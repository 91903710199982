@import "../common/styles/init";

.GamesList {
    padding: 13px 0 15px;
    @include mq(md) {
        padding: 20px 0 30px; }

    &-Wrapper {
        padding: 10px; }

    &-List {
        display: flex;
        flex-wrap: wrap;
        margin-right: -25px; }

    &-Item {
        &_noslider {
            width: 33.33%;
            padding-right: 25px;
            margin-bottom: 20px; } }

    &-Link {
        display: block;
        text-decoration: none;
        text-align: center; }

    &-Placeholder {
        display: block;
        width: 100%;
        height: auto; }

    &-Img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        filter: drop-shadow(0 5px 10px rgba(0,0,0, .7)); }

    &-ImgHolder {
        display: block;
        position: relative;
        width: 100%;
        height: auto;
        margin-bottom: 13px;
        @include mq(md) {
            margin-bottom: 20px; } }

    &-Loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transform-origin: 50%;
        animation: preloader-spin 1s steps(12, end) infinite; }

    &-NameWrap {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis; }

    &-Name {
        font-size: 16px;
        line-height: .8;
        height: 30px;
        @include mq(md) {
            font-size: 24px;
            height: 45px; } }

    &-SliderWrapper {
        .swiper-container {
            padding: 10px; } } }
