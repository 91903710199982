@import "../common/styles/init";

.Button {
    display: inline-block;
    font-size: 22px;
    text-transform: uppercase;
    font-weight: 300;
    text-decoration: none;
    border: none;
    line-height: 50px;
    height: 50px;
    border-radius: 20px;
    box-shadow: 0 0 25px rgba(0,0,0, .5);
    padding: 0 35px;
    cursor: pointer;
    @include mq(md) {
        line-height: 70px;
        height: 70px;
        font-size: 30px;
        box-shadow: 0 0 40px rgba(0,0,0, .5); }

    &:focus {
        outline: none; }

    &_style {
        &_dark {
            background: #98fef0;
            &,
            &:link,
            &:visited,
            &:hover,
            &:active {
                color: #253545; } }

        &_light {
            background: #ffffff;
            &,
            &:link,
            &:visited,
            &:hover,
            &:active {
                color: #65c04b; } } }


    &-Wrapper {
        text-align: center;
        padding: 25px;
        @include mq(md) {
            padding: 40px; } } }
