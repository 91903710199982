@import "../../components/common/styles/init";

.HomePage {
    &-Section {
        overflow: hidden;

        &_name {
            &_online {
                position: relative;
                z-index: 10;
                box-shadow: 0 15px 40px rgba(0, 0, 0, 0.6); }

            &_android {
                padding-top: 50px;
                background: #65c04b url("./images/android.png") top 10px right -15px/60% no-repeat;
                @include mq(md) {
                    padding-top: 100px; } } } }

    &-HelpWrapper {
        background: #65c04b url("./images/android-sm.png") top 10px left/40% no-repeat; } }
