@import "../../components/common/styles/init";

.GamePage {
    color: #9afef0;
    font-weight: 300;

    &-TitleImage {
        height: auto;
        width: 100%; }

    &-Title {
        position: relative;
        padding: 15px 10px;
        margin: 0;
        text-transform: uppercase;
        font-size: 22px;
        font-weight: 300;
        text-align: center;
        @include mq(md) {
            padding: 25px 10px;
            font-size: 30px; }
        @include mq(lg) {
            padding: 35px 10px;
            font-size: 40px; } }

    &-Hr {
        display: block;
        border-color: #9afef0;
        margin: 0; }

    &-Big {
        margin: 0 0 15px;
        font-size: 20px;
        font-weight: 300;
        @include mq(md) {
            margin-bottom: 25px;
            font-size: 24px; }
        @include mq(lg) {
            margin-bottom: 35px;
            font-size: 30px; } }

    &-Content {
        padding: 20px 25px 25px;
        @include mq(md) {
            padding: 30px 40px 40px; } }

    &-Text {
        margin: 0;
        font-size: 16px;
        @include mq(md) {
            font-size: 20px; }
        @include mq(lg) {
            font-size: 24px; } } }
