@import "../common/styles/init";

.Footer {
    position: relative;
    padding: 15px 10px;
    margin-top: auto;
    font-size: 12px;
    z-index: 100;
    @include mq(md) {
        padding: 30px 20px;
        font-size: 14px; }

    &_color {
        &_main {
            background: #253545;
            color: #9afef0;
            a {
                &,
                &:link,
                &:visited,
                &:hover,
                &:active {
                    color: #9afef0; } } }
        &_green {
            background: #64c04b;
            color: #1a3f1f;
            a {
                &,
                &:link,
                &:visited,
                &:hover,
                &:active {
                    color: #1a3f1f; } } } }

    &-Text {
        font-weight: 300;
        line-height: 1;
        padding: 0;
        p {
            &:last-child {
                margin-bottom: 0; } } }

    &-Links {
        padding: 0;
        margin: 0 0 1em;
        list-style-type: none; }

    &-LinksItem {
        margin-bottom: 1em;
        &:last-child {
            margin-bottom: 0; } }

    &-Link {
        font-weight: 300; } }
