@import "../common/styles/init";

.Corner {
    width: 180px;
    height: 80px;
    background: #ffcc00;
    color: #670100;
    text-align: center;
    padding: 5px;
    transform:  rotate(45deg) translateX(-51%);
    pointer-events: none;
    @include mq(md) {
        width: 220px;
        height: 100px;
        padding: 10px; }

    &-Text {
        text-transform: uppercase;
        font-size: 13px;
        @include mq(md) {
            font-size: 16px; }
        i {
            font-style: normal;
            font-size: 20px;
            @include mq(md) {
                font-size: 24px; } } } }
