@import "../common/styles/init";

.PopupQR {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0, .85);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;

    &-Body {
        background: #253545;
        color: #88EDE0;
        width: 96%;
        max-width: 300px;
        padding: 45px 20px 20px;
        border-radius: 2px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        box-shadow: 0 0 10px rgba(#000, .6);
        @include mq(md) {
            max-width: 460px; } }

    &-Close {
        position: absolute;
        top: 20px;
        right: 20px;
        background: transparent;
        border: none;
        height: 25px;
        width: 25px;
        padding: 0;
        cursor: pointer;
        &:focus {
            outline: none; }
        &:after,
        &:before {
            content: '';
            position: absolute;
            background: #88EDE0;
            height: 20px;
            width: 4px;
            top: 50%;
            left: 50%; }
        &:after {
            transform: translate(-50%, -50%) rotate(45deg); }
        &:before {
            transform: translate(-50%, -50%) rotate(-45deg); }

        &:hover {
            &:after,
            &:before {
                background: #72d7ca; } } }

    &-Title {
        font-size: 24px;
        font-weight: 300;
        margin-top: 0; }

    &-Text {
        font-size: 18px;
        font-weight: 300; }

    &-enter {
        opacity: 0; }
    &-enter-active {
        opacity: 1;
        transition: opacity 300ms; }
    &-exit {
        opacity: 1; }
    &-exit-active {
        opacity: 0;
        transition: opacity 300ms; } }
