@import "../common/styles/init";

.SliderWide {
    display: block;
    width: 100%;
    overflow: hidden;
    margin-top: -40px;

    &-Wrapper {
        width: 120%;
        position: relative;
        left: 50%;
        transform: translateX(-50%); }

    &-Item {
        position: relative; }

    &-Link {
        display: block;
        text-decoration: none; }

    &-ImgHolder {
        display: block;
        position: relative;
        overflow: hidden;
        box-shadow: 0 5px 30px rgba(0,0,0, .5);
        z-index: 10;
        margin-bottom: 10px; }

    &-Img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        display: block;
        width: 100%;
        z-index: 10; }

    &-Content {
        display: block;
        overflow: hidden;
        position: relative;
        z-index: 15; }

    &-Pagination {
        display: flex;
        width: 100vw !important;
        max-width: 720px;
        left: 50% !important;
        transform: translate3d(-50%, 0, 0) !important; }

    &-Placeholder {
        display: block;
        width: 100%;
        height: auto;
        z-index: 1; }

    &-Name {
        font-size: 18px;
        font-weight: 300;
        display: block;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        @include mq(md) {
            font-size: 24px; } }

    &-Tag {
        font-size: 14px;
        font-weight: 300;
        display: block;
        text-align: center;
        @include mq(md) {
            font-size: 18px; } }


    .swiper-pagination-bullet {
        flex: 1 0 auto;
        height: 8px;
        border-radius: 0;
        margin: 0 !important;
        width: auto !important;
        background: #274d1c;
        opacity: 1;
        @include mq(md) {
            height: 10px; }
        &-active {
            background: #ffcc00; } }

    .swiper-container {
        padding: 40px 0; } }
