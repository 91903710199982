@import "../common/styles/init";

.NavButtons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style-type: none;
    padding: 25px 0 10px 15px;
    margin: 0 0 -25px;
    @include mq(md) {
        padding: 40px 0 10px 25px;
        margin: 0 0 -40px; }

    &-Item {
        margin: 0 15px 7px 0;
        @include mq(md) {
            margin: 0 25px 15px 0; } }

    &-Link {
        display: inline-block;
        line-height: 48px;
        background-color: transparent;
        font-size: 20px;
        font-weight: 300;
        text-transform: uppercase;
        border: 1px solid #fff;
        border-radius: 20px;
        padding: 0 15px;
        text-decoration: none;
        white-space: nowrap;
        text-overflow: ellipsis;
        @include mq(md) {
            border-width: 2px;
            line-height: 66px;
            font-size: 30px;
            border-radius: 30px; }
        &,
        &:link,
        &:visited,
        &:hover,
        &:active {
            color: #fff; }

        &_active {
            background: #fff;
            &,
            &:link,
            &:visited,
            &:hover,
            &:active {
                color: #253545;
                box-shadow: 0 0 40px rgba(0,0,0, .5); } }

        &_body {
            &_blue {
                border-color: #99fff4;
                &,
                &:link,
                &:visited,
                &:hover,
                &:active {
                    color: #99fff4; }

                &.NavButtons-Link_active {
                    background: #99fff4;
                    &,
                    &:link,
                    &:visited,
                    &:hover,
                    &:active {
                        color: #253545; } } } }

        &_color {
            &_green {
                &.NavButtons-Link_active {
                    &,
                    &:link,
                    &:visited,
                    &:hover,
                    &:active {
                        color: #65c04b; } } } } } }



