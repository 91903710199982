@import "../common/styles/init";

.TagsFilter {
    margin-bottom: 5px;

    &-Item {
        width: auto; }

    &-Button {
        display: block;
        line-height: 48px;
        background-color: transparent;
        color: #ffffff;
        font-size: 25px;
        font-weight: 300;
        text-decoration: none;
        border-radius: 20px;
        padding: 0 15px;
        border: 1px solid;
        cursor: pointer;
        @include mq(md) {
            line-height: 66px;
            font-size: 30px;
            border-radius: 30px;
            padding: 0 25px;
            border-width: 2px; }

        &:focus {
            outline: none; }

        &_active {
            background: #ffffff;
            border-color: #fff;
            box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
            @include mq(md) {
                box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.5); }
            &,
            &:link,
            &:visited,
            &:hover,
            &:active {
                color: #253545; } }

        &_color {
            &_green {
                &.TagsFilter-Button_active {
                    &,
                    &:link,
                    &:visited,
                    &:hover,
                    &:active {
                        color: #65c04b; } } } }

        &_body {
            &_blue {
                color: #99fff4;
                border-color: #99fff4;
                &.TagsFilter-Button_active {
                    background: #99fff4;
                    &,
                    &:link,
                    &:visited,
                    &:hover,
                    &:active {
                        color: #253545; } } } } }


    .swiper-container {
        padding: 20px 10px;
        @include mq(md) {
            padding: 40px 10px; } } }
