@import "../../components/common/styles/init";

.ErrorPage {
    color: #98fef0;
    text-align: center;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &-Text {
        font-size: 35px;
        font-weight: 300;
        margin: 0;
        @include mq(md) {
            font-size: 50px; } }

    &-LgText {
        font-size: 116px;
        font-weight: 700;
        margin: 0;
        @include mq(md) {
            font-size: 173px; } }

    &-Button {
        align-self: center;
        margin-top: 20%; } }
