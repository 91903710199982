.SliderPre {
    position: relative;
    padding-top: 40px;

    &-Wrapper {
        position: relative;
        overflow: hidden;
        background: #253545;
        box-shadow: 0 0 30px rgba(0,0,0, .7);
        &:before {
            content: '';
            display: block;
            position: absolute;
            height: 4px;
            left: 0;
            right: 0;
            top: 0;
            background: #ffcc00;
            z-index: 10; } }

    &-Item {
        position: relative;
        box-shadow: 0 0 15px rgba(0,0,0, .6), 0 0 3px rgba(0,0,0, .7); }

    &-Img {
        display: block;
        height: auto;
        width: 100%; }

    &-Corner {
        position: absolute;
        z-index: 15; } }
