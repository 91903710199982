.ConditionsPage {
    &-Content {
        padding: 30px 10px 60px;
        color: #99fff4;
        font-weight: 300;
        a {
            &:link,
            &:visited,
            &:hover,
            &:active {
                color: #99fff4; } }

        p {
            &:last-child {
                margin-bottom: 0; } } }

    &-List {
        list-style: none;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 0;
        padding-right: 40px; }

    &-Item {
        margin-right: 10px;
        &:last-child {
            margin-right: 0; } }

    &-Button {
        border: none;
        background: transparent;
        color: #99fff4;
        font-weight: 700;
        cursor: pointer;
        padding: 0;
        &_style {
            &_active {
                border-bottom: 2px solid; } }

        &:focus {
            outline: none; } } }
