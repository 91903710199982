@import "../common/styles/init";

.SliderTall {
    width: 100%;
    padding-top: 15px;
    @include mq(md) {
        padding-top: 35px; }

    &-Wrapper {
        width: 140%;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        padding: 0 0 25px;
        @include mq(md) {
            padding: 0 0 40px; } }

    &-Item {
        position: relative;
        box-shadow:  0 5px 20px 0 rgba(0, 0, 0, 0.5);
        overflow: hidden; }

    &-Link {
        position: relative;
        display: block;

        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0; } }

    &-Placeholder {
        display: block;
        height: auto;
        width: 100%; }

    &-Img {
        position: absolute;
        display: block;
        top: 0;
        bottom: 0;
        object-fit: contain;
        width: 100%;
        height: 100%; }

    &-Text {
        pointer-events: none;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
        line-height: .85;
        padding: 0 5px 15px;
        font-size: 16px;
        font-weight: 400;
        text-transform: uppercase;
        @include mq(md) {
            font-size: 24px;
            padding: 0 10px 15px; } }

    &-Tag {
        font-size: 14px;
        text-transform: none;
        @include mq(md) {
            font-size: 18px; } }

    &-Pagination {
        max-width: 720px;
        width: 100vw !important;
        left: 50% !important;
        transform: translateX(-50%);
        display: flex;
        height: 10px;
        bottom: 0 !important; }

    &_style {
        &_dark {
            .SliderTall {
                &-Text {
                    color: #fff; }

                &-Link {
                    &:after {
                        background: linear-gradient(180deg, rgba(37,53,70,0) 50%, rgba(37,53,70,0.5) 65%, rgba(37,53,70,0.66) 75%, rgb(37,53,70) 100%); } } } }

        &_light {
            content: 'test';
            .SliderTall {
                &-Wrapper {
                    background: #9afef2;
                    box-shadow: 0 0 30px rgba(0, 0, 0, 0.7); }

                &-Text {
                    color: #243545; }

                &-Link {
                    &:after {
                        background: linear-gradient(180deg, rgba(155,254,242,0) 50%, rgba(155,254,242,0.5) 65%, rgba(155,254,242,0.66) 75%, rgb(155,254,242) 100%); } } } } }



    .swiper-pagination-bullet {
        flex: 1 0 auto;
        border-radius: 0;
        margin: 0 !important;
        height: 8px;
        background: #224251;
        border: 1px solid #224251;
        opacity: 1;
        @include mq(md) {
            height: 10px; }
        &-active {
            background: #9afef2;
            border: 1px solid #224251; } }

    .swiper-container {
        padding: 15px 0 30px;
        @include mq(md) {
            padding: 20px 0 48px; } } }
