@import "../common/styles/init";

.SliderGame {
    &-Img {
        display: block;
        width: 100%; }

    &-Button {
        position: absolute;
        height: 50px;
        width: 50px;
        background: rgba(#000, .6) center center/25% no-repeat;
        border-radius: 50%;
        border: none;
        top: 50%;
        transform: translateY(-50%);
        z-index: 10;
        @include mq(md) {
            height: 80px;
            width: 80px; }

        &_type {
            &_prev {
                margin-left: 20px;
                left: 0;
                background-image: url('../common/images/arrow-prev.svg'); }

            &_next {
                margin-right: 20px;
                right: 0;
                background-image: url('../common/images/arrow-next.svg'); } } }

    &-Item {
        box-shadow: 0 0 20px rgba(0,0,0, .8); }

    &-ThumbImg {
        display: block;
        height: auto;
        width: 100%; }

    &-Pagination {
        display: flex;
        height: 10px;
        bottom: 0 !important; }


    &-SwiperMain {
        .swiper-container {
            padding: 30px 10px;
            margin: -30px 0 -20px; } }

    &-SwiperThumb {
        .swiper-container {
            padding: 10px 10px 30px; }

        .swiper-pagination-bullet {
            flex: 1 0 auto;
            border-radius: 0;
            margin: 0 !important;
            height: 10px;
            background: #224251;
            border: 1px solid #224251;
            opacity: 1;
            &-active {
                background: #9afef2;
                border: 1px solid #224251; } } } }
