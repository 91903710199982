@import "../common/styles/init";

.SliderMain {
    position: relative;
    overflow: hidden;

    &-Item {
        background: #eee; }

    &-Button {
        position: absolute;
        height: 50px;
        width: 50px;
        background: rgba(#000, .6) center center/25% no-repeat;
        border-radius: 50%;
        border: none;
        top: 50%;
        transform: translateY(-50%);
        z-index: 10;
        @include mq(md) {
            height: 80px;
            width: 80px; }

        &_type {
            &_prev {
                margin-left: 10px;
                left: 0;
                background-image: url('../common/images/arrow-prev.svg');
                @include mq(md) {
                    margin-left: 20px; } }

            &_next {
                margin-right: 10px;
                right: 0;
                background-image: url('../common/images/arrow-next.svg');
                @include mq(md) {
                    margin-right: 20px; } } } }

    &-Corner {
        position: absolute;
        z-index: 15; }

    &-Link {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 140px;
        background: rgba(#000, .6);
        text-align: center;
        line-height: 45px;
        color: #fff;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 300;
        border-radius: 20px;
        @include mq(md) {
            line-height: 70px;
            width: 210px;
            font-size: 24px;
            border-radius: 30px; } }

    &-Image {
        display: block;
        width: 100%;
        height: 200px;
        object-fit: cover;
        @include mq(md) {
            height: 280px; } } }
