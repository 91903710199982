@import "../common/styles/init";

.GamesListLarge {
    padding: 15px 0 0;
    @include mq(md) {
        padding: 20px 0 0; }

    &-List {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        padding-bottom: 15px;
        @include mq(md) {
            padding-bottom: 25px; } }

    &-Item {
        width: 45%;
        padding-bottom: 20px;
        @include mq(md) {
            width: 42%;
            padding-bottom: 35px; } }

    &-Link {
        display: block;
        text-decoration: none; }

    &-ImageHolder {
        display: block;
        position: relative;
        box-shadow: 0 5px 30px rgba(0,0,0, .5);
        margin-bottom: 10px;
        width: 100%; }

    &-Placeholder {
        display: block;
        width: 100%;
        height: auto; }

    &-Img {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover; }

    &-Loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transform-origin: 50%;
        animation: preloader-spin 1s steps(12, end) infinite; }

    &-Name {
        display: block;
        text-align: center;
        text-transform: uppercase;
        font-size: 18px;
        @include mq(md) {
            font-size: 24px; } } }


@keyframes preloader-spin {
    100% {
        transform: translate(-50%, -50%) rotate(360deg); } }
