@import "../common/styles/init";

.Header {
    position: relative;
    background: url("./images/header-background.png") bottom/cover no-repeat;

    &:after {
        content: '';
        display: block;
        position: absolute;
        height: 4px;
        left: 0;
        right: 0;
        bottom: 0;
        background: #ffcc00;
        z-index: 10; }

    &-Link {
        display: block;
        padding: 10px;
        text-align: center;
        text-decoration: none;
        @include mq(md) {
            padding: 20px 10px; }
        @include mq(lg) {
            padding: 30px 10px; } }

    &-Title {
        margin: 0 0 10px;
        font-size: 30px;
        font-weight: 800;
        text-transform: uppercase;
        color: #c0fcfb;
        text-shadow: 0 0 20px rgba(69, 187, 182, 1);
        line-height: 1;
        @include mq(md) {
            margin: 0 0 30px;
            font-size: 50px; } }

    &-Logo {
        width: 70%;
        max-width: 200px;
        @include mq(md) {
            max-width: 320px; } } }
